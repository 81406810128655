.registration-wrapper {
  grid-column: 1/3;
}
.error-text {
  margin-bottom: 0;
  line-height: 1;
}

.phone-field {
  position: relative;
  width: 100%;
}

.phone-field::before {
  color: white;
  font-size: 18px;
  content: '+';
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
}

.input {
  color: white;
  background: transparent;
  border: 1px rgba(255, 255, 255, 1) solid;
  padding: 25px 20px;
  border-radius: 5px;
  font-family: 'DM Sans', sans-serif;
  font-size: 18px;
  line-height: 18px;
  font-weight: 400;
  width: 100%;
}

.input::placeholder {
  color: white;
  font-family: 'DM Sans', sans-serif;
  font-size: 18px;
  line-height: 18px;
  font-weight: 400;
}

.info-wrapper {
  padding: 75px 25px 110px;
  max-width: 825px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 35px;
}

.info-text {
  font-family: 'Poppins', sans-serif;
  font-size: 40px;
  font-weight: 700;
  line-height: 52px;
  text-align: center;
  color: rgba(30, 55, 83, 1);
}

.info-btn {
  font-family: 'DM Sans', sans-serif;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24px;
  gap: 8px;
  letter-spacing: -0.2px;
  outline: none;
  cursor: pointer;
  border: none;
  background: rgba(0, 143, 248, 1);
  margin: 0;
  font-size: 18px;
  line-height: 18px;
  font-weight: 700;
  color: #fff;
  max-width: 332px;
  width: 100%;
  transition: all 0.3s ease-in-out;
}

.info-btn:hover {
  background: white;
  color: rgba(0, 143, 248, 1);
}

.info-link {
  color: rgba(0, 143, 248, 1);
}

.info-btn p {
  margin: 0;
}

.btn-secondary {
  width: 100%;
  padding: 25px;
  color: rgba(0, 143, 248, 1);
  background: white;
  font-family: 'DM Sans', sans-serif;
  font-weight: 700;
  font-size: 18px;
  border-radius: 5px;
  border: none;
  outline: none;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.btn-secondary:hover {
  background: transparent;
  color: white;
  outline: 2px solid white;
}

.social-link {
  display: flex;
  align-items: center;
  gap: 8px;
}

.field {
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
}

.form {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-right: 7px;
  padding-bottom: 50px;
}

.form::-webkit-scrollbar {
  width: 7px;
}

.form::-webkit-scrollbar-track {
  background: transparent;
}

.form::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgba(255, 255, 255, 1);
}

.form::-webkit-scrollbar-thumb:hover {
  background: rgba(255, 255, 255, 0.7);
}

.footer {
  background: rgba(0, 30, 65, 1);
  text-align: center;
  color: rgba(255, 255, 255, 0.7);
}

.footer__inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: 38px 70px;
  max-width: 1340px;
  margin: 0 auto;
  gap: 14px;
  height: 100%;
}

.footer-text {
  margin-bottom: 0;
  font-size: 15px;
}

.footer-link {
  color: rgba(255, 255, 255, 0.7);
}
.social-links {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  color: rgba(0, 143, 248, 1);
}

.social-links p {
  margin: 0;
  width: 100%;
}

.header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  color: white;
}

.header-inner {
  padding: 63px 70px;
  margin: 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  max-width: 1340px;
}

.hero-section {
  background-size: cover;
  background-position: center;
  height: 1072px;
  position: relative;
  color: rgba(255, 255, 255, 1);
}

.hero-inner {
  padding: 182px 70px 0;
  margin: 0 auto;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 40px;
  max-width: 1340px;
  height: 100%;
}

.hero-title {
  font-family: 'Poppins', sans-serif;
  font-size: 52px;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 1);
  font-weight: 700;
  letter-spacing: -0.2px;
}

.hero-text {
  font-size: 24px;
  max-width: 459px;
  line-height: 40px;
}

.hero-form {
  background: rgba(0, 143, 248, 1);
  flex: 1;
  padding: 43px 50px;
  max-width: 590px;
  width: 100%;
  border-radius: 16px 16px 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.hero-general {
  flex: 1;
  margin-top: 120px;
  max-height: 361px;
}

.form-title {
  color: rgba(255, 255, 255, 1);
  text-transform: uppercase;
  font-family: 'Poppins', sans-serif;
  font-size: 50px;
  line-height: 55px;
  font-weight: 600;
}

.triangle {
  position: absolute;
  width: 100%;
  height: 300px;
  background-color: white;
  bottom: -1px;
  left: 0;
  clip-path: polygon(-2% 100%, 100% 50%, 100% 100%);
  border: none;
  z-index: 10;
  filter: drop-shadow(0px -6px 95px rgba(0, 0, 0, 0.45));
}

.triangle::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background: inherit;
  clip-path: inherit;
  top: 0;
  left: 0;

  /* Тінь */
  box-shadow: 0 -6px 95px rgba(0, 0, 0, 0.45);
  z-index: -1;
}

@media (max-width: 1024px) {
  .hero-general {
    margin-top: 0;
  }
}

@media (max-width: 768px) {
  .hero-inner {
    padding: 100px 25px 0;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: nowrap;
    gap: 0;
  }

  .hero-general {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .hero-title {
    font-size: 39px;
    line-height: 50px;
    letter-spacing: 0;
    text-transform: capitalize;
  }
  .hero-text {
    font-size: 19px;
    line-height: 32px;
  }
  .hero-general {
    margin-top: 0;
    flex: 0;
  }
  .hero-form {
    padding: 18px;
  }
  .form-title {
    font-size: 43px;
  }
  .form {
    padding-bottom: 0;
    gap: 15px;
  }
  .header-inner {
    padding: 30px 58px;
  }
  .input {
    padding: 15px 24px;
  }
  .triangle {
    height: 120px;
  }
}

@media (max-width: 600px) {
  .social-links {
    flex: 1;
  }
  .footer__inner {
    padding: 50px 58px;
    letter-spacing: -0.2px;
    font-weight: 400;
  }
  .header-inner {
    justify-content: center;
  }
}
